import {FC, ReactNode} from 'react';
import Image from 'next/image';
import {Popover} from '@headlessui/react';
import {ChevronDownIcon, ChevronRightIcon} from "@heroicons/react/solid";
import LabelLink from "@/components/centralbrisbanedental/links/LabelLink/LabelLink";

interface HeroProps {
  backgroundImage?: string
  backgroundImageAlt?: string
  labelAccentTextValue?: string
  labelTextValue?: string
  title?: string
  titleTwo?: string
  paragraph?: string
  associatedHealthFunds?: string[] | false;
  children?: ReactNode | ReactNode[]
}

const Hero: FC<HeroProps> = ({
  backgroundImage,
  backgroundImageAlt,
  labelDestination,
  labelAccentTextValue,
  labelTextValue,
  title,
  titleTwo,
  paragraph,
  associatedHealthFunds,
  children
}) => {

  // console.log("HERO", backgroundImage)
  return (
    <>
      <section
        reactDiv-type="container-only"
        id="hero-home"
        className="relative"
      >
        {/* Backround As Div */}
        <div className="absolute inset-x-0 bottom-0 h-full bg-[#400068b3]"/>
        {/* Content of Hero */}
        <div className="px-4 lg:px-0 relative shadow-xl">

          {/* This is the background image for the hero */}
          <div className="absolute inset-0 h-full z-[-1]">
            {backgroundImage ? (
              <img
                className="h-full w-full object-cover object-[0%_35%]"
                src={backgroundImage}
                alt={backgroundImageAlt}
              />
            ) : null}
            <div className="absolute inset-0 bg-[#17aaab] mix-blend-multiply"/>
          </div>

          <div
            id="hero-container"
            className="container mx-auto py-4 sm:py-24"
          >
            <div className="h-full lg:grid lg:grid-cols-12 lg:gap-8 content-center">

              <div
                className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:flex-col lg:justify-around"
              >

                {/* Link to Finance Component */}
                <LabelLink
                  destination={labelDestination && labelDestination || "/static/pdf/promotion-terms-and-conditions.pdf"}
                  colour="text-[#fff] hover:text-[#17aaab]"
                  accentValue={labelAccentTextValue && labelAccentTextValue || "Teeth Whitening just $660*"}
                  labelValue={labelTextValue && labelTextValue || "Find out more today"}
                  labelBackgroundColour="bg-[#ff6600]"
                  labelColour="text-black"
                  linkShape={true}
                  linkShapeColour="text-gray-500"
                />

                <div>

                  <h1
                    className="font-['Sigmar_One, cursive'] mt-4 sm:mt-5 lg:mt-6 text-5xl tracking-tight font-extrabold text-white sm:leading-none">
                    <span className="md:block">{title}</span>{' '}
                    <span className="text-[#ff6600] md:block">{titleTwo}</span>
                  </h1>

                  <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                    {paragraph}
                  </p>

                  {/* NOTE Health Fund Logos */}
                  <>
                    {!!associatedHealthFunds && (
                      <>
                        <p className="mt-8 text-sm text-white uppercase tracking-wide font-semibold sm:mt-10">
                          Associated Health Funds
                        </p>

                        <div className="mt-5 w-full sm:mx-auto lg:ml-0">

                          <div className="flex items-start justify-between">

                            <div className="flex justify-center px-1">
                              <Image
                                layout="intrinsic"
                                height="40px" // 182px
                                width="160px" //420px
                                className="h-9 sm:h-10"
                                src="/static/images/hero/smilecomau.png"
                                alt="Smile.com.au health fund logo icon transparent"
                              />
                            </div>

                            <div className="flex justify-center px-1">
                              <Image
                                layout="intrinsic"
                                height="40px" // 182px
                                width="160px" //420px
                                className="h-9 sm:h-10"
                                src="/static/images/hero/nib-logo.png"
                                alt="NIB health fund logo icon transparent"
                              />
                            </div>

                            <div className="flex justify-center px-1">
                              <Image
                                layout="intrinsic"
                                height="40px" // 182px
                                width="160px" //420px
                                className="h-9 sm:h-10"
                                src="/static/images/hero/hcf-logo.png"
                                alt="HCF health fund logo icon transparent"
                              />
                            </div>

                            <div className="flex justify-center px-1">
                              <Image
                                layout="intrinsic"
                                height="40px" // 182px
                                width="160px" //420px
                                className="h-9 sm:h-10"
                                src="/static/images/hero/cbhs-logo.png"
                                alt="CBHS health fund logo icon transparent"
                              />
                            </div>

                          </div>

                        </div>
                      </>
                    )}

                  </>
                </div>

              </div>

              {/* Appointment Form */}
              {children ?? children}

            </div>
          </div>

        </div>
      </section>
    </>
  )
}

export default Hero


