/* This example requires Tailwind CSS v2.0+ */
import {FC, ReactNode} from "react";
import SectionLayout from "@/layouts/SectionLayout";
import tailwindClassnames from "@/modules/tailwind/tailwindClassnames";

interface ContentBackgroundImageProps {
  backgroundImage?: string
  value?: string
  classes?: object
  children?: ReactNode | ReactNode[]
}

const ContentBackgroundImage: FC<ContentBackgroundImageProps> = ({
  backgroundImage,
  value,
  classes,
  children
}) => {

  return (

    <>
      <SectionLayout classes={classes && classes}>

        <>
          <div className="bg-fixed bg-contain bg-top opacity-100 filter saturate-50 mix-blend-multiply" style={{
            backgroundImage: `url("${backgroundImage}")`
          }}>
            <div className="relative container mx-auto px-12 py-20 text-center lg:col-span-2">
              <blockquote className="mt-6 text-white mix-blend-normal bg-black/80 py-2 rounded-md">
                <p className="text-xl font-bold sm:text-3xl">
                  {value}
                </p>
              </blockquote>
            </div>
          </div>
        </>

      </SectionLayout>
    </>
  )
}

export default ContentBackgroundImage
