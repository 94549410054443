import {ChangeEvent, FC, ReactNode, useState} from 'react';
import {Popover} from '@headlessui/react';
import {ChevronDownIcon, ChevronRightIcon} from "@heroicons/react/solid";
import axios from "axios";
import Form from "@/modules/core/reactDivAPI/components/CoreElements/Wrappers/Form";
import {
  useForm,
  FormProvider,
} from 'react-hook-form';
import classnames from 'classnames';
import * as yup from 'yup';

interface AppointmentRequestFormProps {
  formCallToAction?: string
  stepOneOptions?: string[]
  stepTwoOptions?: string[]
  children?: ReactNode | ReactNode[]
}

const AppointmentRequestForm: FC<AppointmentRequestFormProps> = ({
                                                                   formCallToAction,
                                                                   stepOneOptions,
                                                                   stepTwoOptions,
                                                                   children,
                                                                 }) => {
  const [stepOneState, setStepOneState] = useState(false)
  const [stepTwoState, setStepTwoState] = useState(false)
  const [completeState, setCompleteState] = useState(false);

  // NOTE Setup Form Methods
  const {register, formState: {errors}, handleSubmit, setValue, trigger} = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  const checkStepOneState = (e: any) => {
    if (e.target.value === "newpatient" || e.target.value === "existingpatient") {
      // setPatientType(e.target.value)
      setValue('patientType', e.target.value)
      setStepOneState(true)
    } else {
      setStepOneState(false)
    }
  }
  const checkStepTwoState = async (e: any) => {
    const result = await trigger(["firstName", "lastName", "phone", "email"]);
    console.log("result", result)
    // console.log("errors", errors)
    if (result) {
      setStepOneState(false)
      setStepTwoState(true)
    }
  }
  const checkAppointmentType = (e: any) => {
    console.log()
    if (e.target.value !== "whatisyourappointmentfor?") {
      // setPatientType(e.target.value)
      setValue('appointmentType', e.target.value)
      // setStepOneState(true)
    } else {
      // setStepOneState(false)
    }
  }
  const submitAppointmentRequest = async (formData) => {
    // console.log('appointment form submitted - formData: ', formData);
    try {
      const response = await axios.post(
        '/api/appointment',
        {
          patientType: formData.patientType,
          firstName: formData.firstName,
          lastName: formData.lastName,
          phone: formData.phone,
          email: formData.email,
          day: formData.day,
          hour: formData.hour,
          minute: formData.minute,
          appointmentType: formData.appointmentType,
        }
      );
      // const data = response?.data;
      // console.log('theappointmentformresponse', data)
    } catch (err) {
      // console.log(err);
    }

  }

  const handleFormSubmission = async (data, event) => {
    // console.log('handleAppointmentFormSubmission', data);
    event.preventDefault();
    const result = await trigger(["appointmentType"]);
    if (result) {
      submitAppointmentRequest(data);
      setCompleteState(true);
    }
  };

  return (
    <div className="mt-16 sm:mt-24 lg:mt-[initial] lg:col-span-6 self-center">
      <div className="bg-white sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden">
        <div className="px-4 py-8 sm:px-10">
          <div>
            <p
              className="text-md font-medium text-gray-700 text-center whitespace-pre-line leading-[1]"
            >
              {formCallToAction ?? `Add a value for \`formCallToAction\``}
            </p>
          </div>
          <div className="mt-6 relative">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t border-gray-300"/>
            </div>
          </div>
          <div className="mt-10">
            <form
              onSubmit={
                handleSubmit(
                  (data, event) => handleFormSubmission(data, event)
                )
              }
              className="space-y-6"
            >
              <div
                className={!stepOneState && !stepTwoState ? `flex` : `hidden`}
              >
                <select
                  id="patientType"
                  name="patientType"
                  className="mt-1 block w-full pr-10 py-2 text-[1rem] sm:text-[1.25rem] border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
                  // defaultValue={stepOneOptions ? stepOneOptions[0] : ""}
                  onChange={(e) => checkStepOneState(e)}
                  register={register("patientType")}
                >
                  {stepOneOptions?.map((option, index) => {
                    return (
                      <option
                        value={index === 0 ? `""` : `${option.toLowerCase().replace(/\s+/g, '')}`}
                      >
                        {option}
                      </option>)
                  })}
                </select>
              </div>
              {/* Customer Details */}
              <div
                className={stepOneState ? `block` : `hidden`}
              >
                <label htmlFor="firstName" className="sr-only">
                  What is your first name?
                </label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  autoComplete="firstName"
                  placeholder="What is your first name?"
                  className={classnames(
                    "block w-full text-black bg-[#F5F0E8] h-10",
                    "rounded-sm pl-4 text-normal",
                    "placeholder:text-optional-color outline-0 placeholder:ease-in placeholder:duration-300 focus:placeholder:text-transparent"
                    // "block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  )}
                  onChange={(e) => setValue('firstName', e.target.value)}
                  register={register("firstName", {required: true})}
                />
                {errors.firstName &&
                <p className="mt-2 bg-[#FF000025] px-4 font-[400] text-[#FF0000] rounded-md">Please enter your first
                  name.</p>}
              </div>
              <div
                className={stepOneState ? `block` : `hidden`}
              >
                <label htmlFor="lastName" className="sr-only">
                  What is your last name?
                </label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  autoComplete="lastName"
                  placeholder="What is your last name?"
                  className={classnames(
                    "block w-full text-black bg-[#F5F0E8] h-10",
                    "rounded-sm pl-4 text-normal",
                    "placeholder:text-optional-color outline-0 placeholder:ease-in placeholder:duration-300 focus:placeholder:text-transparent"
                    // "block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  )}
                  onChange={(e) => setValue('lastName', e.target.value)}
                  register={register("lastName", {required: true})}
                />
                {errors.lastName &&
                <p className="mt-2 bg-[#FF000025] px-4 font-[400] text-[#FF0000] rounded-md">Please enter your last
                  name.</p>}
              </div>
              <div
                className={stepOneState ? `block` : `hidden`}
              >
                <label htmlFor="phone" className="sr-only">
                  Mobile number
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  autoComplete="phone"
                  placeholder="Mobile number or best contact"
                  className={classnames(
                    "block w-full text-black bg-[#F5F0E8] h-10",
                    "rounded-sm pl-4 text-normal",
                    "placeholder:text-optional-color outline-0 placeholder:ease-in placeholder:duration-300 focus:placeholder:text-transparent"
                    // "block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  )}
                  onChange={(e) => setValue('phone', e.target.value)}
                  register={register("phone", {
                    required: true,
                    min: 8,
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "invalid contact number"
                    }
                  })}
                />
                {errors.phone &&
                <p className="mt-2 bg-[#FF000025] px-4 font-[400] text-[#FF0000] rounded-md">Please enter a valid
                  contact number.</p>}
              </div>
              <div
                className={stepOneState ? `block` : `hidden`}
              >
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  autoComplete="email"
                  placeholder="Email"
                  className={classnames(
                    "block w-full text-black bg-[#F5F0E8] h-10",
                    "rounded-sm pl-4 text-normal",
                    "placeholder:text-optional-color outline-0 placeholder:ease-in placeholder:duration-300 focus:placeholder:text-transparent"
                    // "block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                  )}
                  onChange={(e) => setValue('email', e.target.value)}
                  register={register("email", {
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "invalid email address"
                    }
                  })}
                />
                {errors.email &&
                <p className="mt-2 bg-[#FF000025] px-4 font-[400] text-[#FF0000] rounded-md">
                  Please enter a valid email.
                </p>
                }
              </div>
              {/* Next Button */}
              <div
                className={stepOneState ? `block` : `hidden`}
              >
                <button
                  type="button"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-black bg-[#ff6600] hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={(e) => checkStepTwoState(e)}
                >
                  Choose Preferred Appointment Time
                </button>
              </div>
              {/* Preferred Appointment Time */}
              <div
                className={!completeState && stepTwoState ? `flex` : `hidden`}
              >
                <div>
                  <label htmlFor="day" className="block text-sm font-medium text-gray-700">
                    Preferred Day
                  </label>
                  <select
                    id="day"
                    name="day"
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    // defaultValue="Please select"
                    onChange={(e) => setValue('day', e.target.value)}
                    register={register("day", {value: "Monday"})}
                  >
                    <option>Monday</option>
                    <option>Tuesday</option>
                    <option>Wednesday</option>
                    <option>Thursday</option>
                    <option>Friday</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="hour" className="block text-sm font-medium text-gray-700">
                    Preferred Time
                  </label>

                  <div className="flex">

                    <select
                      id="hour"
                      name="hour"
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      onChange={(e) => setValue('hour', e.target.value)}
                      register={register("hour", {value: "8"})}
                    >
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                      <option>11</option>
                      <option>12</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                    </select>

                    <select
                      id="minute"
                      name="minute"
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      onChange={(e) => setValue('minute', e.target.value)}
                      register={register("minute", {value: "00"})}
                    >
                      <option>00</option>
                      <option>30</option>
                    </select>

                  </div>

                </div>
              </div>
              {/* Appointment Reason */}
              <div
                className={!completeState && stepTwoState ? `block` : `hidden`}
              >
                <select
                  id="appointmentType"
                  name="appointmentType"
                  className="mt-1 block w-full pr-10 py-2 text-[1rem] sm:text-[1.25rem] border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
                  // defaultValue={stepOneOptions ? stepOneOptions[0] : ""}
                  onChange={(e) => checkAppointmentType(e)}
                  register={register("appointmentType", {
                    required: true
                  })}
                >
                  {stepTwoOptions?.map((option, index) => {
                    return (
                      <option
                        value={index === 0 ? `""` : `${option.toLowerCase().replace(/\s+/g, '')}`}
                      >
                        {option}
                      </option>
                    )
                  })}
                </select>
                {errors.appointmentType &&
                <p className="mt-2 bg-[#FF000025] px-4 font-[400] text-[#FF0000] rounded-md">
                  Please select your appointment type.
                </p>
                }
                {/*<label htmlFor="comment" className="block text-sm font-medium text-gray-700">*/}
                {/*  Add your comment*/}
                {/*</label>*/}
                {/*<div className="mt-1">*/}
                {/*  <textarea*/}
                {/*    rows={4}*/}
                {/*    name="comment"*/}
                {/*    id="comment"*/}
                {/*    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"*/}
                {/*    defaultValue={''}*/}
                {/*    onChange={(e) => formMethods.setValue('comment', e.target.value)}*/}
                {/*    register={formMethods.register("comment")}*/}
                {/*  />*/}
                {/*</div>*/}
              </div>

              {/* Booking Button */}
              <div
                className={!completeState && stepTwoState ? `block` : `hidden`}
              >
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-black bg-[#ff6600] hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Request Appointment
                </button>
              </div>

            </form>
            <div
              className={completeState ? `block` : `hidden`}
            >
              <p
                className="text-md font-medium text-gray-700 text-center whitespace-pre-line leading-[1]"
              >
                Thank you!
              </p>
            </div>
          </div>
        </div>
        {/* NOTE Terms and Conditions of Contact */}
        <div className="px-4 py-6 bg-gray-50 border-t-2 border-gray-200 sm:px-10">
          <p className="text-xs leading-5 text-gray-500">
            By contacting us, you agree to our{' '}
            <a href="/static/pdf/terms-and-conditions.pdf"
               className="font-medium text-gray-900 hover:underline">
              Terms
            </a> &{' '}
            <a href="/static/pdf/privacy-policy.pdf"
               className="font-medium text-gray-900 hover:underline">
            Privacy Policy
            </a>
            ,{' '}
            {/* NOTE Data Policy Link */}
            <>
              {/*<a href="#" className="font-medium text-gray-900 hover:underline">*/}
              {/*  Data Policy*/}
              {/*</a>{' '}*/}
              {/*and{' '}*/}
            </>
            {/* NOTE Cookies Policy */}
            <span className="text-gray-500 no-underline">
              including our ability to contact you to confirm your appointment.
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default AppointmentRequestForm
