import {FC, ReactNode} from 'react';
import Image from 'next/image';
import {Popover} from '@headlessui/react';
import {ChevronDownIcon, ChevronRightIcon} from "@heroicons/react/solid";

interface LabelLinkProps {
  accentValue?: string
  destination?: string
  colour?: string
  containerBackgroundColour?: string
  linkShape?: boolean
  linkShapeColour?: string
  labelValue?: string
  labelBackgroundColour?: string
  labelColour?: string
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const LabelLink: FC<LabelLinkProps> = ({
  accentValue,
  destination,
  colour,
  linkShape,
  linkShapeColour,
  labelValue,
  labelBackgroundColour,
  labelColour
}) => {

  return (
    <div>

      <a
        href={destination ?? "#"}
        className={classNames(
          `${colour ? colour : "text-white hover:text-gray-200"}`,
          "sm:text-base lg:text-sm xl:text-base",
          "mb- inline-flex items-center bg-gray-900 rounded-full p-1 pr-2 no-underline"
        )}
      >
        <span
          className={classNames(
            `${labelBackgroundColour ? labelBackgroundColour : ""}`,
            `${labelColour ? labelColour : ""}`,
            "px-3 py-0.5 text-xs font-bold leading-5 uppercase tracking-wide rounded-full"
          )}
        >
          {accentValue ?? "Accent value"}
        </span>
        <span className="ml-4 text-sm font-bold">{labelValue ?? labelValue}</span>
        {/*{linkShape ?*/}
        {/*  <ChevronRightIcon*/}
        {/*    className={classNames(*/}
        {/*      `${linkShapeColour ? linkShapeColour : ""}`,*/}
        {/*      "ml-2 w-5 h-5"*/}
        {/*    )}*/}
        {/*    aria-hidden="true"*/}
        {/*  /> : null}*/}
      </a>

    </div>
  )
}

export default LabelLink


