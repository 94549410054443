import React, {useEffect, useState} from 'react';
import {CommonServerSideParams} from '@/app/types/CommonServerSideParams';
import {OnlyBrowserPageProps} from '@/layouts/web/types/OnlyBrowserPageProps';
import {SSGPageProps} from '@/layouts/web/types/SSGPageProps';
import ReactDivWebMain from '@/modules/core/reactDivWeb/components/Web/Main/Main';
import WebLayout from '@/layouts/web/components/WebLayout';
import {
  // getWebLayoutStaticPaths,
  getWebLayoutStaticProps,
} from '@/layouts/web/webLayoutSSG';
// import useCustomer from '@/modules/core/data/hooks/useCustomer';
// import { Customer } from '@/modules/core/data/types/Customer';
import {createLogger} from '@/modules/core/logging/logger';
import {
  GetStaticPaths,
  GetStaticProps, GetStaticPropsResult,
  NextPage,
} from 'next';

import ReactDivWebAnnouncementTopBar from '@/modules/core/reactDivWeb/components/Announcements/TopBar/TopBar';

import {StaticPropsInput} from '@/app/types/StaticPropsInput';
import {getDashboardContentEditorLayoutStaticProps} from '@/layouts/dashboard/contentEditor/dashboardContentEditorLayoutSSG';
import {getReactDivApiConfig} from '@/modules/core/reactDivAPI/utils/getApiConfig';
import getSystemShellBySingularAPIID
  from '@/modules/core/reactDivAPI/utils/queries/system/getSystemShellBySingularAPIID';
import getShelledPageBySingularAPIID from '@/modules/core/reactDivAPI/utils/queries/web/getShelledPageBySingularAPIID';
import deepmerge from 'deepmerge';
import getClientProjectContentBySystemShellID
  from '@/modules/core/reactDivAPI/utils/queries/web/getClientProjectContentBySystemShellID';
import getSystemShelledFieldsBySystemShellID
  from '@/modules/core/reactDivAPI/utils/queries/system/getSystemShelledFieldsBySystemShellID';
import Annoucement from "@/components/centralbrisbanedental/Announcement";
import Topbar from "@/components/centralbrisbanedental/Topbar";
import NavBar from "@/components/centralbrisbanedental/NavBar/NavBar";
import Hero from "@/components/centralbrisbanedental/Hero/Hero";
import AppointmentRequestForm
  from "@/components/centralbrisbanedental/userActions/AppointmentRequestForm/AppointmentRequestForm";
import CTABannerStandard from "@/components/centralbrisbanedental/CTABannerStandard/CTABannerStandard";
import CTABannerImageDescriptive
  from "@/components/centralbrisbanedental/CTABannerImageDescriptive/CTABannerImageDescriptive";
import CTABannerSplitTiles from "@/components/centralbrisbanedental/CTABannerSplitTiles/CTABannerSplitTiles";
import TwoColumnContent from "@/components/centralbrisbanedental/TwoColumnContent/TwoColumnContent";
import ContentBackgroundImage from "@/components/centralbrisbanedental/ContentBackgroundImage/ContentBackgroundImage";
import Footer from "@/components/centralbrisbanedental/Footer/Footer";
import axios from "axios";
import includes from 'lodash.includes';


const fileLabel = 'pages/index';
const logger = createLogger({ // eslint-disable-line no-unused-vars,@typescript-eslint/no-unused-vars
  fileLabel,
});

/**
 * Only executed on the server side at build time.
 * Necessary when a page has dynamic routes and uses "getStaticProps".
 */
// export const getStaticPaths: GetStaticPaths<CommonServerSideParams> = getPublicLayoutStaticPaths({fallback:true});
// export const getStaticPaths: GetStaticPaths<CommonServerSideParams> = getPublicLayoutStaticPaths();

/**
 * Only executed on the server side at build time.
 *
 * @return Props (as "SSGPageProps") that will be passed to the Page component, as props.
 *
 * @see https://github.com/vercel/next.js/discussions/10949#discussioncomment-6884
 * @see https://nextjs.org/docs/basic-features/data-fetching#getstaticprops-static-generation
 */
export const getStaticProps: GetStaticProps<SSGPageProps, CommonServerSideParams> = async (props: StaticPropsInput): Promise<GetStaticPropsResult<SSGPageProps>> => {
  const commonStaticProps: GetStaticPropsResult<SSGPageProps> = await getWebLayoutStaticProps()(props);
  if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
    logger.info('getStaticProps commonStaticProps', commonStaticProps); // eslint-disable-line no-console
  }

  if ('props' in commonStaticProps) {
    const config = getReactDivApiConfig();
    const [
      // currentPageShell  // NOTE Web Module
    ] = await Promise.all([
      // getShellBySingleApiId({config, singleApiId: 'pages'})  // NOTE Web Module
    ]);

    const [
      // Below is Web Module
      // currentShelledPage,
      // currentClientProjectSEOSettings,
      // currentShelledPageSeoSettings, // NOTE This is a dependency, should loop through these
      // currentClientProjectSEOSettings,
      // Above is Web Module
    ] = await Promise.all([
      // Below is Web Module
      // getShelledPageBySingularAPIID({config, slug: 'home'}),
      // getSystemShelledFieldsBySystemShellID({config, systemShellID: '630f756db23725aedbc16e7d'}),
      // getClientProjectContentBySystemShellID({
      //   config,
      //   clientID: commonStaticProps.props.reactDivClientID,
      //   projectID: commonStaticProps.props.reactDivClientProjectID,
      //   systemShellID: '630f756db23725aedbc16e7d',
      // }),
      // getBySingularAPIID({config, slug: "home"}),
      // Above is Web Module
    ]);

    return deepmerge(commonStaticProps, {
      props: {
        // Below is Web Module
        // currentShelledPage,
        // currentShelledPageSeoSettings,
        // currentClientProjectSEOSettings,
        // Above is Web Module
        builtAt: new Date().toISOString(),
      },
      // revalidate: regenerationDelay,
    });
    // return commonStaticProps;
  } else {
    return commonStaticProps;
  }

};
/**
 * SSG pages are first rendered by the server (during static bundling).
 * Then, they're rendered by the client, and gain additional props (defined in OnlyBrowserPageProps).
 * Because this last case is the most common (server bundle only happens during development stage), we consider it a default.
 * To represent this behaviour, we use the native Partial TS keyword to make all OnlyBrowserPageProps optional.
 *
 * Beware props in OnlyBrowserPageProps are not available on the server.
 */
type Props = {} & SSGPageProps<Partial<OnlyBrowserPageProps>>;

let homeContentA: any = [
  {
    element: 'flexRow',
    classes: {
      display: "flex flex-col sm:flex-row", // Note this is hard coded and we should better handle responsive CSS
      // margin: "mt-8"
    },
    children: [
      {
        element: 'flexCol',
        classes: {
          display: "flex-row lg:flex-col basis-1/2",
          margin: "lg:px-8 lg:py-4"
        },
        children: [
          {
            element: 'heading',
            type: 'h3',
            value: 'Where is our Brisbane dental clinic located?',
            classes: {
              margin: "mb-4",
              colour: "text-gray-900",
              fontWeight: "bold",
              fontSize: "text-2xl",
              letterSpacing: "tracking-tight",
              lineHeight: "leading-8",
            }
          },
          {
            element: 'paragraph',
            type: 'richParagraph',
            value: 'Our Brisbane dental clinic is conveniently located right in the Brisbane CBD at 289 Queen Street. ' +
              'Our dentists are interested in the solutions that will provide you with the best long term health for your teeth. ' +
              'We also provide cheap Brisbane dentist options for those that are on a budget and can taylor treatment plans for ' +
              'you to suit your own needs.',
            classes: {
              margin: "mb-4",
              colour: "text-gray-900",
              fontSize: "text-md",
              letterSpacing: "tracking-tight",
              lineHeight: "leading-8",
            }
          },
          {
            element: 'image',
            imageProperties: {
              layout: 'fixed',
              alt: 'happy toothy character',
              src: '/static/images/tiles/toothy-happy-a.webp',
              height: 266,
              width: 320,
            },
            imageClasses: {
              textAlign: "center",
              margin: "mt-4"
            }
          }
        ]
      },
      {
        element: 'flexCol',
        classes: {
          display: "flex-row lg:flex-col basis-1/2",
          margin: "lg:px-8 lg:py-4"
        },
        children: [
          {
            element: 'heading',
            type: 'h3',
            value: 'Important information about our practice.',
            classes: {
              margin: "mb-4",
              colour: "text-gray-900",
              fontWeight: "bold",
              fontSize: "text-2xl",
              letterSpacing: "tracking-tight",
              lineHeight: "leading-8",
            }
          },
          {
            element: 'richList',
            type: 'ul',
            value: [
              "We are a general dental clinic in Brisbane cbd.",
              "Our dental clinic is not open Saturday or Sunday.",
              "We are open Monday to Friday.",
              "We are not able to provide you with bulk billing.",
              "Bulk billing is not available for any dentist.",
              "We don’t have a price list as each case is different.",
              "We are a preferred provider for many health funds.",
              "We can treat you for a dental emergency.",
              "We are not associated with the dental school.",
              "We are not associated with the dental hospital.",
              "We can accomodate you around your job for an appointment.",
              "We do provide cheap deals for patients such as;",
              "<ul class='list-disc list-inside'>" +
              "<li>$0/No Gap dental checkup and clean.</li>" +
              "<li><a href='/static/pdf/promotion-terms-and-conditions.pdf'>Terms and Conditions</a></li>" +
              "<li>Free Consultation for all cosmetic dental work.</li>" +
              "<li><a href='/static/pdf/promotion-terms-and-conditions.pdf'>Terms and Conditions</a></li>" +
              "</ul>"
            ]
          }
        ]
      },
    ]
  },
]
let homeContentB: any = [
  {
    element: 'flexRow',
    classes: {
      display: "flex flex-col sm:flex-row",
      margin: "mt-8"
    },
    children: [
      {
        element: 'flexCol',
        classes: {
          display: "flex-row sm:flex-col basis-full sm:basis-1/2",
          margin: "sm:px-8 sm:py-4"
        },
        children: [
          {
            element: 'heading',
            type: 'h3',
            value: 'Where is our Brisbane dental clinic located?',
            classes: {
              margin: "mb-4",
              colour: "text-gray-900",
              fontWeight: "bold",
              fontSize: "text-2xl",
              letterSpacing: "tracking-tight",
              lineHeight: "leading-8",
            }
          },
          {
            element: 'paragraph',
            type: 'richParagraph',
            value: 'At Central Brisbane Dental, we are ' +
              'passionate about long term oral health and delivering that for our patients in a relaxed but still ' +
              'professional environment.  Situated conveniently on the ground floor of the Manor Apartments, right next' +
              ' to the GPO and diagonally opposite Post Office Square, Central Brisbane Dental is named for its' +
              ' easy-to-find, centrally-positioned location in the Brisbane city.  ' +
              'Central Train Station and Queen Street and King George Square Bus Stations are just minutes away, ' +
              'while secure parking and a taxi rank are available at MacArthur Central a few steps away.',
            classes: {
              margin: "mb-4",
              colour: "text-gray-900",
              fontSize: "text-md",
              letterSpacing: "tracking-tight",
              lineHeight: "leading-8",
            }
          },
          {
            element: 'paragraph',
            type: 'richParagraph',
            value: 'We aim to provide our patients with ' +
              'information about their dental well-being and a range of options on how to treat dental issues.  ' +
              'As dentists, we are always looking to promote healthy teeth and gums for life, and we really do like to ' +
              'focus on helping you maintain good dental health for the long term.  ' +
              'We do this by focussing on preventing dental problems by providing the right advice on oral hygiene, ' +
              'diet and habits that might be detrimental to your oral health such as clenching and grinding of your ' +
              'teeth, and snoring and sleep apnoea.  We offer a wide variety of general dental services aimed at ' +
              'improving your dental well-being and overall quality of life, ranging from white fillings, crowns and ' +
              'wisdom teeth extractions to teeth whitening, dental implants and invisible braces.  ' +
              'If you have a dental emergency, we understand that you may be in considerable discomfort and will ' +
              'fit you in on the day to relieve your pain and manage your problem.',
            classes: {
              margin: "my-4",
              colour: "text-gray-900",
              fontSize: "text-md",
              letterSpacing: "tracking-tight",
              lineHeight: "leading-8",
            }
          },
        ]
      },
      {
        element: 'flexCol',
        classes: {
          display: "flex-row sm:flex-col basis-full sm:basis-1/2",
          margin: "sm:px-8 sm:py-4"
        },
        children: [
          {
            element: 'heading',
            type: 'h3',
            value: 'Important information about our practice.',
            classes: {
              margin: "mb-4",
              colour: "text-gray-900",
              fontWeight: "bold",
              fontSize: "text-2xl",
              letterSpacing: "tracking-tight",
              lineHeight: "leading-8",
            }
          },
          {
            element: 'paragraph',
            type: 'richParagraph',
            value: 'We also know that not every patient likes to ' +
              'see a dentist, and we know the reasons for this are diverse.  ' +
              'It could be that you have a fear of feeling any discomfort during treatment, or you might find visits ' +
              'to the dentist can simply bring on anxiety.  You might even just be worried about the cost of the work ' +
              'that may need to be done.  As dentists, we know that the most expensive and painful dental situations ' +
              'come from procrastinating.  When you come into our practice you’ll see straight away how much of a ' +
              'relaxed environment we have created, with pop culture references littered throughout our practice ' +
              'and projectors in each surgery to watch your favourite DVD during treatment.  ' +
              'Our dentists are professional and well respected in our industry, but we are also real people.  ' +
              'We can relate to you and provide you with a range of treatment options that work for you.',
            classes: {
              margin: "mb-4",
              colour: "text-gray-900",
              fontSize: "text-md",
              letterSpacing: "tracking-tight",
              lineHeight: "leading-8",
            }
          },
          {
            element: 'paragraph',
            type: 'richParagraph',
            value: 'Start looking after your dental health today ' +
              'by coming in for a general or cosmetic consultation.  ' +
              'In most cases, there are no out-of-pocket costs for this initial consultation.  ' +
              "<a href=\'/static/pdf/promotion-terms-and-conditions.pdf\'>View Terms and Conditions.</a> " +
              'You do not have to fear any kind of pain or discomfort as assessments are made by thorough visual ' +
              'inspection, not by the out-dated method of poking and scratching with a sharp instrument.  ' +
              'Let’s start by taking a look at everything, talking about it and going over options for promoting ' +
              'strong dental health, and why that is important and very manageable for you.',
            classes: {
              margin: "my-4",
              colour: "text-gray-900",
              fontSize: "text-md",
              letterSpacing: "tracking-tight",
              lineHeight: "leading-8",
            }
          },
        ]
      },
    ]
  },
]

/**
 * Example of a public page.
 */
const Index: NextPage<Props> = (props): JSX.Element => {
  if (process.env.NEXT_PUBLIC_APP_STAGE !== 'production') {
    logger.info('Index props', props); // eslint-disable-line no-console
  }


  return (
    <>
      <WebLayout
        // reactDivClientID={}
        // reactDivClientProjectID={}
        hideFooter={false}
        pageSeoSettings={{
          seoTitle: 'Brisbane City Dentist @ 289 Queen St',
          seoDescription: 'Central Brisbane Dental provide affordable dental treatment with regular promotions for new patients, as well as regular patient specials. Brisbane dentist Dr Vincent Wan has over 20 yrs experience. ',
          seoImage: '/static/images/tiles/sunbaking-toothy-teeth-whitening.png.webp',
          favicon: '/static/images/favicon/favicon-96.png',
        }}
      >

        <Hero
          backgroundImage="/static/images/background/background-hero.jpg"
          backgroundImageAlt="The team at Central Brisbane Dental"
          title="Your Brisbane CBD"
          titleTwo="Dental Practice"
          paragraph="Central Brisbane Dental is passionate about providing affordable dental care. We've partnered with major health funds to provide affordable access to the highest quality dentists in Brisbane city."
        >
          <AppointmentRequestForm
            formCallToAction={`
                Plan your next appointment with ${"\n"}
                Dr Vincent or Dr Allen
              `}
            stepOneOptions={[
              "Are you a new or existing patient?",
              "New patient",
              "Existing patient"
            ]}
            stepTwoOptions={[
              "What is your appointment for?",
              "Check and clean",
              "Tooth pain",
              "Gum disease",
              "Cosmetic dentistry",
              "Dental emergency",
            ]}
          />
        </Hero>
        <CTABannerStandard
          headingValue="New Patient Deals."
          headingLineTwoValue="Regular Patient Specials."
          paragraph="At Central Brisbane Dental we believe that cost should not be a barrier to regular, routine, dental health care. Our deals are always promoted well ahead of time with clearly visible end-dates."
          buttonOneValue="View Current Deals"
          buttonOneDestination="/static/pdf/promotion-terms-and-conditions.pdf"
          buttonTwoValue="View Promotion Terms*"
          buttonTwoDestination="/static/pdf/promotion-terms-and-conditions.pdf"
          dynamicAttributeClassNames={{
            backgroundColor: "bg-[#FF6600]",
            headingColor: "text-[#131313]",
            headingLineTwoColor: "text-[#400068]"
          }}
        />
        <TwoColumnContent
          title="Things You Need To Know"
          heading="How to get to our dental clinic in Brisbane city & things to know before coming in."
          content={homeContentA}
        />
        <ContentBackgroundImage
          backgroundImage="/static/images/background/toothy-battle-scene-banner.jpg"
          value="Our team of dentists fight gum disease, tooth decay and more, protecting the citizens of Brisbane city day and night."
          classes={{
            position: 'relative',
            dynamicBackground: '#ff6600'
          }}
        />
        <CTABannerImageDescriptive
          imageProperties={{
            layout: 'intrinsic',
            alt: 'happy toothy character',
            type: 'dimensions',
            src: '/static/images/tiles/sunbaking-toothy-teeth-whitening.png.webp',
            height: 285,
            width: 412.5,
          }}
          title="Philips Zoom"
          heading="Teeth Whitening for just $660!"
          description="Come in for a free consultation."
          buttonText="View terms and conditions"
          buttonDestination="/static/pdf/promotion-terms-and-conditions.pdf"
          imageClasses={{
            textAlign: "center",
            padding: "p-5"
          }}
          titleClasses={{
            dynamicColour: '#ff6600',
            fontSize: 'text-base',
            fontWeight: 'semibold',
            letterSpacing: 'tracking-wider',
            uppercase: true,
          }}
          headingClasses={{
            margin: 'mt-2',
            fontSize: 'text-2xl sm:text-3xl',
            fontWeight: 'extrabold',
            letterSpacing: 'tracking-tight',
            colour: 'text-white',
          }}
          descriptionClasses={{
            margin: 'mt-3',
            fontSize: 'text-lg',
            colour: 'text-white'
          }}
          buttonClasses={{
            border: 'border border-transparent rounded-md',
            display: 'inline-flex',
            padding: 'px-5 py-3',
            dynamicBackground: '#17aaab',
            fontSize: 'text-base',
            fontWeight: 'medium',
            colour: 'text-black',
          }}
          classes={{
            position: 'relative',
            dynamicBackground: '#070707',
          }}
        />
        <TwoColumnContent
          title="Things You Need To Know"
          heading="How to get to our dental clinic in Brisbane city & things to know before coming in."
          content={homeContentB}
        />
        <CTABannerSplitTiles
          dentist="vincent"
          heading="Did you know?"
          value="You can get 2 checkup and cleans a year for $0 or a very small out-of-pocket when you come and see us and use your health fund."
          buttonText="View terms and conditions"
          buttonDestination="/static/pdf/promotion-terms-and-conditions.pdf"
          hasInset={true}
          insetClasses={{
            display: 'hidden lg:block',
            position: 'absolute top-0 inset-x-0',
            height: 'h-1/2',
            // dynamicBackground: '#ff6600'
            dynamicBackground: '#400068'
          }}
          buttonClasses={{
            border: 'border border-transparent rounded-md',
            display: 'inline-flex',
            padding: 'px-5 py-3',
            dynamicBackground: '#17aaab',
            fontSize: 'text-base',
            fontWeight: 'medium',
            colour: 'text-black',
          }}
          classes={{
            position: 'relative',
            dynamicBackground: '#fff',
            padding: 'py-16'
          }}
        />
      </WebLayout>
    </>
  );
};

export default Index;
