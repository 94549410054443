import {FC, ReactNode} from "react";
import SectionLayout from "@/layouts/SectionLayout";
import tailwindClassnames from "@/modules/tailwind/tailwindClassnames";
import {ExternalLinkIcon} from '@heroicons/react/solid'
import ImageElement from "@/components/centralbrisbanedental/content/Image/Image";
import Heading from "@/components/centralbrisbanedental/content/Heading/Heading";
import Text from "@/components/centralbrisbanedental/content/Text/Text";
import classnames from 'classnames';

interface DynamicAttributeClassNames {
  backgroundColor?: string,
  headingColor?: string,
  headingLineTwoColor?: string,
}

interface CTABannerImageDescriptiveProps {
  dynamicAttributeClassNames?: DynamicAttributeClassNames
  bannerImage?: string
  imageProperties?: object
  title?: string
  heading?: string
  description?: string
  buttonText?: string
  buttonDestination?: string
  imageClasses?: object
  titleClasses?: object
  headingClasses?: object
  descriptionClasses?: object
  buttonClasses?: object
  classes?: object
  children?: ReactNode | ReactNode[]
}

const CTABannerImageDescriptive: FC<CTABannerImageDescriptiveProps> = ({
                                                                         dynamicAttributeClassNames,
                                                                         bannerImage,
                                                                         imageProperties,
                                                                         title,
                                                                         heading,
                                                                         description,
                                                                         buttonText,
                                                                         buttonDestination,
                                                                         imageClasses,
                                                                         titleClasses,
                                                                         headingClasses,
                                                                         descriptionClasses,
                                                                         buttonClasses,
                                                                         classes,
                                                                         children
                                                                       }) => {
  return (
    <SectionLayout classes={classes && classes}>

      <div className="h-56 bg-[#17aaab] sm:h-72 md:absolute md:right-0 md:h-full md:w-1/2">

        {imageProperties && <ImageElement
          //@ts-ignore TODO
          imageProperties={imageProperties}
          imageClasses={imageClasses && imageClasses}
        />}

      </div>

      <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">

        <div className="md:mr-auto md:w-1/2 md:pl-10">

          {title && <Text
						type="p"
						value={title}
            // @ts-ignore
						classes={titleClasses && titleClasses}
					/>}

          {heading && <Heading
						type="h2"
						value={heading}
            // @ts-ignore
						classes={headingClasses && headingClasses}
					/>}

          {description && <Text
						type="p"
						value={description}
            // @ts-ignore
						classes={descriptionClasses && descriptionClasses}
					/>}

          <div className="mt-8">

            <div className="inline-flex rounded-md shadow">

              <a
                href={buttonDestination && buttonDestination || "#"}
                className={classnames(
                  buttonClasses && tailwindClassnames(buttonClasses),
                  "no-underline"
                )}
                // className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
              >
                {buttonText && buttonText || "View terms and conditions"}
                {/*<ExternalLinkIcon className="-mr-1 ml-3 h-5 w-5 text-black" aria-hidden="true"/>*/}
              </a>

            </div>

          </div>

        </div>

      </div>

    </SectionLayout>
  )
}

export default CTABannerImageDescriptive
