import {FC, ReactNode} from "react";
import classnames from 'classnames';
import SectionLayout from "@/layouts/SectionLayout";

interface DynamicAttributeClassNames {
  backgroundColor?: string,
  headingColor?: string,
  headingLineTwoColor?: string,
}

interface CTABannerStandardProps {
  children?: ReactNode | ReactNode[]
  dynamicAttributeClassNames?: DynamicAttributeClassNames
  headingValue?: string
  headingLineTwoValue?: string
  paragraph?: string
  buttonOneValue?: string
  buttonOneDestination?: string
  buttonOneColour?: string
  buttonTwoValue?: string
  buttonTwoColour?: string
  buttonTwoDestination?: string
}

const CTABannerStandard: FC<CTABannerStandardProps> = ({
                                                         children,
                                                         dynamicAttributeClassNames,
                                                         headingValue,
                                                         headingLineTwoValue,
                                                         paragraph,
                                                         buttonOneValue,
                                                         buttonOneDestination,
                                                         buttonOneColour,
                                                         buttonTwoValue,
                                                         buttonTwoColour,
                                                         buttonTwoDestination,
                                                       }) => {
  return (
    <div className={dynamicAttributeClassNames && dynamicAttributeClassNames.backgroundColor || "bg-[#17aaab]"}>

      <div className={
        classnames("max-w-2xl",
          "mx-auto",
          "text-center",
          "p-4",
          "sm:py-10",
          "sm:px-6",
          "lg:px-8"
        )}>

        <h2
          className={
            classnames("text-3xl",
              "font-['Sigmar_One']",
              "font-extrabold",
              "sm:text-4xl",
              `${dynamicAttributeClassNames && dynamicAttributeClassNames.headingColor || "text-black"}`,
            )}>

          <span
            className="block"
          >
            {headingValue && headingValue || "Add A Heading"}
          </span>

          {headingLineTwoValue && (
            <span
              className={
                classnames("block",
                  `${dynamicAttributeClassNames && dynamicAttributeClassNames.headingLineTwoColor || "text-[#fff]"}`
                )}>
              {headingLineTwoValue}
            </span>
          )}

        </h2>

        {paragraph ? (
          <p className={
            classnames("mt-4",
              "text-lg",
              "leading-6",
              "text-black",
              "font-semibold",
            )}>
            {paragraph}
          </p>
        ) : null}

        {buttonOneValue || buttonTwoValue ? (

          <div
            className="flex flex-wrap justify-evenly"
          >

            <a
              href={buttonOneDestination && buttonOneDestination || "#"}
              className={
                classnames(
                  "mt-8",
                  "w-full",
                  "inline-flex",
                  "items-center",
                  "justify-center",
                  "px-5",
                  "py-3",
                  "border",
                  "border-transparent",
                  "text-base",
                  "font-medium",
                  "rounded-md",
                  "text-white",
                  "hover:bg-white",
                  "hover:text-[#400068]",
                  "hover:ring-2",
                  "hover:ring-[#400068]",
                  "transition ease-in-out delay-[89ms]  ",
                  "sm:w-auto",
                  `${buttonOneColour && buttonOneColour || "bg-[#400068]"}`,
                  "no-underline"
                )}>
              {buttonOneValue}
            </a>

            {buttonTwoValue ? (
              <a
                href={buttonTwoDestination && buttonTwoDestination || "#"}
                className={
                  classnames(
                    "mt-8",
                    "w-full",
                    "inline-flex",
                    "items-center",
                    "justify-center",
                    "px-5",
                    "py-3",
                    "border",
                    "border-transparent",
                    "text-base",
                    "font-medium",
                    "rounded-md",
                    "bg-[#17AAAB]",
                    "text-black",
                    "hover:bg-[#131313]",
                    "hover:text-[#17AAAB]",
                    "hover:ring-2",
                    "hover:ring-[#17AAAB]",
                    "transition ease-in-out delay-[89ms]  ",
                    "sm:w-auto",
                    "no-underline"
                  )}>
                {buttonTwoValue}
              </a>
            ) : null}

          </div>

        ) : null}

      </div>

    </div>
  )
}

export default CTABannerStandard
